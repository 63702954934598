import { motion } from "framer-motion";
import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CardDeck } from "reactstrap";
import SaveMoney from "../assets/all-images/screenshot.jpg";
import roadImg from "../assets/images/Road_Assistance-removebg-preview.png";
import blog3 from "../assets/all-images/blog-img/blog-1.jpg";
import blog4 from "../assets/all-images/blog-img/blog-2.jpg";
import blog5 from "../assets/all-images/blog-img/blog-3.jpg";
import blog6 from "../assets/all-images/blog-img/blog-4.png";
import blog7 from "../assets/all-images/blog-img/blog-5.jpg";
import blog8 from "../assets/all-images/blog-img/blog-6.jpg";
import blog9 from "../assets/all-images/blog-img/blog-9.jpg";
import blog10 from "../assets/all-images/blog-img/blog-10.jpg";
import HelmetComponent from "../components/Helmet/HelmetComponent";
import "../styles/blog-details.css";
import newBlog1 from '../assets/all-images/blog1.jpg';
import newBlog2 from '../assets/all-images/blog2.jpg'
const Blog = () => {
  return (
    <>
      <HelmetComponent title={"Christchurch | BLOGS"} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <div className="blogs-cont container d-flex flex-column flex-wrap gap-2">
          <h1>Explore Insights from Our Writers | BLOGS</h1>
          <CardDeck className="blogs d-flex justify-content-around flex-wrap gap-2">
            <Card className="blog-single-container">
              <Link to="/10_Ways_to_Save_Money_on_Car_Rental_in_Christchurch">
                <Card.Img variant="top" src={SaveMoney} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    10 Ways to Save Money on Car Rental in Christchurch
                  </Card.Title>
                  <Card.Text>
                    Car rental services have become an integral part of travel,
                    providing convenience and flexibility to explore new
                    destinations. If you find yourself planning a trip to
                    Christchurch,..
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>

            <Card className="blog-single-container">
              <Link to="/AA_Road_Assistance">
                <Card.Img variant="top" src={roadImg} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>AA Roadside Assistance</Card.Title>
                  <Card.Text>
                    When you find yourself in need of emergency roadside
                    assistance while renting a car in Christchurch, New
                    Zealand..
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/What_Are_the_Best_Options_for_Car_Rental_in_Christchurch">
                <Card.Img variant="top" src={blog3} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    What Are the Best Options for Car Rental in Christchurch?
                  </Card.Title>
                  <Card.Text>
                    If you're planning a trip to Christchurch, New Zealand,
                    renting a car is one of the most convenient ways to explore
                    the city...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/Why_Choose_Car_Rental_in_Christchurch_for_Your_Next_Trip">
                <Card.Img variant="top" src={blog4} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    Why Choose Car Rental in Christchurch for Your Next Trip?
                  </Card.Title>
                  <Card.Text>
                    When planning a visit to Christchurch, New Zealand, one of
                    the most practical decisions you can make is to opt for a
                    car rental Christchurch...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/What_Makes_Car_Rental_in_Christchurch_Your_Best_Travel_Choice">
                <Card.Img variant="top" src={blog5} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    What Makes Car Rental in Christchurch Your Best Travel
                    Choice?
                  </Card.Title>
                  <Card.Text>
                    Traveling through New Zealand offers a unique blend of
                    stunning landscapes, vibrant cities, and thrilling
                    adventures. When you’re planning...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/Exploring_Christchurch_and_Beyond_Your_Ultimate_Guide_to_Car_Rentals">
                <Card.Img variant="top" src={blog6} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    Exploring Christchurch and Beyond: Your Ultimate Guide to
                    Car Rentals
                  </Card.Title>
                  <Card.Text>
                    Christchurch, a city known for its stunning landscapes,
                    vibrant culture, and historical charm, offers a plethora of
                    experiences for every type of traveler...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/How_Can_Car_Rental_Enhance_Your_Journey">
                <Card.Img variant="top" src={blog7} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    How Can Car Rental Enhance Your Journey from Queenstown to
                    Christchurch?
                  </Card.Title>
                  <Card.Text>
                    When planning a visit to New Zealand, one of the most
                    memorable experiences is the scenic drive from Queenstown to
                    Christchurch. This route not only connects two...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/Looking_for_Affordable_Car_Rentals_at_Christchurch_Airport">
                <Card.Img variant="top" src={blog8} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    Looking for Affordable Car Rentals at Christchurch Airport?
                  </Card.Title>
                  <Card.Text>
                    When planning your trip to Christchurch, New Zealand, one of
                    the first things you’ll need to consider is how you’ll get
                    around. Public transport can be limiting, and taxis can
                    quickly add up. The best option for travelers...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/Top_Places_to_Visit_in_Christchurch_Explore_the_Garden_City">
                <Card.Img variant="top" src={blog10} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>
                    Top Places to Visit in Christchurch: Explore the Garden City
                    with Convenient Car Rental
                  </Card.Title>
                  <Card.Text>
                    Christchurch, often known as the "Garden City," is a vibrant
                    destination filled with a rich mix of natural beauty,
                    history, and modern attractions. Whether you’re visiting for
                    the first time or returning to explore more of what this
                    incredible city...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/Car_Rental_Christchurch">
                <Card.Img variant="top" src={blog9} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>Car Rental Christchurch​</Card.Title>
                  <Card.Text>
                    Christchurch, known as the "Garden City," offers a perfect
                    blend of urban life, beautiful parks, and easy access to New
                    Zealand’s stunning landscapes. Whether you're arriving for
                    business or leisure, having access to a reliable vehicle
                    makes exploring this charming city and its surrounding
                    areas...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/Your_Guide_to_Car_Rental_at_Christchurch_Airport">
                <Card.Img variant="top" src={newBlog1} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>Your Guide to Car Rental at Christchurch Airport: Convenience, Savings, and Adventure Await​</Card.Title>
                  <Card.Text>
                  When planning a trip to Christchurch, New Zealand, one of the first things you’ll want to consider is how to get around the beautiful city and its surrounding areas. While public transportation options exist ...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
            <Card className="blog-single-container">
              <Link to="/Why_Car_Rental_in_Christchurch_is_the_Best_Way_to_Explore_New_Zealand">
                <Card.Img variant="top" src={newBlog2} />
                <Card.Body className="blog-single-container-body">
                  <Card.Title>Why Car Rental in Christchurch is the Best Way to Explore New Zealand
                  ​</Card.Title>
                  <Card.Text>
                  New Zealand is a land of unparalleled beauty, offering diverse landscapes ranging from pristine beaches and dense forests to majestic mountains and serene lakes. One of the best ways to explore this stunning country is by renting a car ...
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
          </CardDeck>
        </div>
      </motion.div>
    </>
  );
};

export default Blog;
