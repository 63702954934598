import React, { useEffect } from "react";
import ReactPlaceholder from "react-placeholder";

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/HelmetComponent";

import { Container, Row, Col, Button } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import AboutSection from "../components/UI/AboutSection";
import ServicesList from "../components/UI/ServicesList";
import "../styles/add.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  allCarsFailure,
  allCarsStart,
  allCarsSuccess,
} from "../components/features/allCarsSlice";
import axiosInstance from "../utils/axiosUtil";
import AlertLayout from "../components/UI/AlertLayout";
import { motion } from "framer-motion";
import FaqHome from "../components/UI/FaqHome";
import ReviewCard from "./ReviewCard";
import { AiFillCar, AiFillSetting } from "react-icons/ai";
import { IoSpeedometerSharp } from "react-icons/io5";

const MyCarItem = (props) => {
  const { images, model, name, automatic, speed, price } = props.item;
  console.log(props,'props');
  const { isCar, search,id } = props;
  const navigate = useNavigate();

  return (
    <Col
      lg={isCar ? "6" : "4"}
      md="6"
      sm="12"
      className="mb-5"
      style={{ height: "fit-content" }}
    >
      <div className="car__item">
        <div
          className="car__img"
          // style={{ ...(!isCar && { height: "23rem" }) }}
        >
          <img
            src={images[0]}
            alt=""
            className={`w-100`}
            style={{ height: "50% !important" }}
          />
        </div>

        <div className="car__item-content  mt-4">
          <h5 className="section__title text-center">{name}</h5>

          {isCar &&
            (search ? (
              <p style={{ textAlign: "center" }}>
                {localStorage.getItem("journeyDays") || 0} Days @ ${price} (per
                day){" "}
                <strong>
                  ${parseInt(localStorage.getItem("journeyDays") || 0) * price}
                </strong>
                <br />
                excluding of insurance, additional-charges and discount.
              </p>
            ) : (
              <h6 className="rent__price text-center mt-2">
                <span className="car-price">${price?.toFixed(2)}</span>
                <span>/day</span>
              </h6>
            ))}

          {isCar && (
            <div className="car__item-info d-flex align-items-center justify-content-between mt-3">
              <div className="car-item-btm">
                <p>
                  <AiFillCar style={{ marginRight: "0.5rem" }} />
                </p>

                <p>{model}</p>
              </div>
              <div className="car-item-btm">
                <p>
                  <AiFillSetting style={{ marginRight: "0.5rem" }} />
                </p>
                <p>{automatic}</p>
              </div>
              <div className="car-item-btm">
                <p>
                  <IoSpeedometerSharp style={{ marginRight: "0.5rem" }} />
                </p>
                <p>{speed}</p>
              </div>
            </div>
          )}
          <div className="car-btn-cont">
            <Button
              disabled={props?.unAvailable}
              onClick={() => navigate(`/cars/${id}`)}
              className=" w-50 car__item-btn-1 car__btn-rent"
            >
              Book Now
            </Button>

            <Button
              disabled={props?.unAvailable}
              className=" w-50 car__item-btn-2 "
            >
              <a
                style={{ textDecoration: "none", color: "#2c5a69" }}
                href="tel:+64-220211997"
              >
                <i class="ri-phone-line"></i> Call Now
              </a>
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
};

const reviews = [
  {
    id: 1,
    name: "Clifford Morris",
    avatar: "C",
    reviewsCount: 1,
    rating: 4.0,
    timeAgo: "3 years ago",
    reviewText:
      "Car was exactly as described. Was met at the Airport when I arrived at Nadi and was met at the Airport when I departed. Excellent service. CPM",
  },
  {
    id: 2,
    name: "Shivneel Sharma",
    avatar: "S",
    reviewsCount: 5,
    rating: 5.0,
    timeAgo: "3 months ago",
    reviewText:
      "Great place to get rental cars. Great customer service. The cars are in good condition.",
  },
  {
    id: 3,
    name: "Josefa Saua",
    avatar: "J",
    reviewsCount: 1,
    rating: 5.0,
    timeAgo: "3 years ago",
    reviewText:
      "I recommend it to all comin to Christ Church looking for rental. Good Quality Cars. Great Customer service.",
  },
  {
    id: 4,
    name: "Moz Malik",
    avatar: "M",
    reviewsCount: 39,
    photosCount: 3,
    rating: 5.0,
    timeAgo: "11 months ago",
    reviewText:
      "Customer service was excellent the company representative was one of the nicest persons I have ever met! Highly recommended!",
  },
];

const servicesArray = [
  {
    id: 1,
    images: [
      "	https://www.carrentalchristchurch.co.nz/static/media/airport_rental1.cdc07f3cb1f59ef2ea85.jpg",
    ],
    name: "Airport Car Rental Christchurch",
  },
  {
    id: 2,
    images: [
      "	https://www.carrentalchristchurch.co.nz/static/media/luxury_rental1.a0dfe5ec10bd9f788f54.jpg",
    ],
    name: "Luxury Car Rental Christchurch",
  },
  {
    id: 3,
    images: [
      "	https://www.carrentalchristchurch.co.nz/static/media/wedding_rental3.dee76c206353b060ed7d.jpg",
    ],
    name: "Wedding Car Rental Christchurch",
  },
];

const CarRentalChristChurch = () => {
  const {
    availableCars,
    unAvailableCars,
    loadingAvailableCars,
    availableCarsErr,
    availableCarsSearch,
  } = useSelector((state) => state.availableCars);
  const { allCars, loadingAllCars, allCarsErr } = useSelector(
    (state) => state.allCars
  );
  console.log(allCars,'cars');
  const dispatch = useDispatch();

  const fetchAllCars = async () => {
    dispatch(allCarsStart());
    try {
      const { data } = await axiosInstance.get(`/api/car/get-car?sort=low`);

      dispatch(allCarsSuccess(data.cars));
    } catch (error) {
      dispatch(allCarsFailure(error?.response?.data?.message));
    }
  };

  useEffect(() => {
    fetchAllCars();
  }, []);

  // useEffect(() => {
  //   console.log("home", cars);
  //   console.log(loading);
  // }, [cars, loading]);

  return (
    <>
      <motion.div
        className="overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet
          title="Christchurch Car Rental 🚗 | Hire | Easy Bookings 🚘"
          metaDesc={
            "Hire the Best Rental Car in Christchurch and get best deals! Book now for great prices and easy car hire. 🌟 | Christchurch Car Rental"
          }
        />
        {/* ============= hero section =========== */}
        <section className="p-0 hero__slider-section ">
          <Row className="text-center d-flex justify-content-center d-sm-none mt-5 pt-3">
            <Col className="col-6 col-sm-5 col-lg-3">
              <button className="header__btn btn ">
                <Link to="/contact_us">
                  <i class="ri-phone-line"></i> Enquire Now
                </Link>
              </button>
            </Col>
            <Col className="col-6 col-sm-5 col-lg-3">
              <button className="header__btn btn ">
                <a href="tel:+64-220211997">
                  <i class="ri-phone-line"></i> Call Now
                </a>
              </button>
            </Col>
          </Row>
          <HeroSlider className="" />
        </section>
        <div className="hero__form">
          <Container>
            <Row className="form__row">
              <Col lg="4" md="4">
                <div className="find__cars-left">
                  <h2>Find your best car here</h2>
                </div>
              </Col>

              <Col lg="8" md="8" sm="12">
                {/* this is car form */}

                <FindCarForm
                // setCars={setCars}
                // setLoading={setLoading}
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* available car section */}
        {availableCarsSearch && (
          <div className="pt-2">
            <Container>
              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAvailableCars}
              >
                <div className="imp-prp">
                  <Row className="justify-content-center">
                    {availableCarsSearch && availableCars?.length === 0 ? (
                      <AlertLayout
                        title={"No cars available!"}
                        body={
                          <>
                            <p>
                              Please select different date and time to see
                              available cars.
                            </p>
                          </>
                        }
                        navLink={"/cars"}
                        btnTitle={"Cars"}
                      />
                    ) : (
                      <>
                        {availableCarsSearch && (
                          <Row className="row-car-list justify-content-center">
                            {availableCars?.length > 0 && (
                              <div className="availableCars-title">
                                <h1>Available cars</h1>
                              </div>
                            )}
                            {availableCars?.map((item) => {
                              console.log(item);
                              return (
                                <MyCarItem
                                  fromHome={true}
                                  item={item}
                                  search={true}
                                  key={item._id}
                                  isCar={true} 
                                  id={item._id}
                                />
                              );
                            })}
                          </Row>
                        )}
                        {availableCarsSearch && (
                          <Row className="row-car-list mt-2 mb-0">
                            {unAvailableCars?.length > 0 && (
                              <div className="availableCars-title">
                                <h1>Un-available cars</h1>
                              </div>
                            )}
                            {unAvailableCars?.map((item) => (
                              <MyCarItem
                                unAvailable={true}
                                item={item}
                                key={item._id}
                                isCar={true}
                              />
                            ))}
                          </Row>
                        )}
                      </>
                    )}
                  </Row>
                </div>
              </ReactPlaceholder>
            </Container>
          </div>
        )}

        <section className="mt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Come with
                </h6>
                <h2 className="section__title">Hot Offers</h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/contact_us" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                <Row className="row-car-list d-flex justify-content-around">
                  {allCars?.slice(0, 6)?.map((item) => (
                    <MyCarItem item={item} key={item._id} id={item._id} isCar={true} />
                  ))}
                </Row>
              </ReactPlaceholder>
            </Row>
          </Container>
          {/* services section */}
          <Container className="pt-5">
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h2 className="section__title">Our Services</h2>
              </Col>

              <Link to="/contact_us" className="home-view-more mb-3">
                View more
              </Link>

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                <Row className="row-car-list d-flex justify-content-around">
                  {servicesArray.map((item) => (
                    <MyCarItem item={item} key={item.id} isCar={false} />
                  ))}
                </Row>
              </ReactPlaceholder>
            </Row>
          </Container>
        </section>

        {/* =========== about section ================ */}
        <AboutSection forHome={true} />

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row>
              <Col lg="12" className="">
                <h2 className="section__title">
                  Explore Christchurch with Ease
                </h2>
                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  Book a rental car easily
                </h6>
                <div className="popular-services-txt">
                  <p>
                    At Car Rental Christchurch, we understand the importance of
                    convenience when it comes to car hire. Our online booking
                    platform, available at www.carrentalchristchurch.co.nz,
                    allows you to book your preferred vehicle quickly and
                    hassle-free. Whether you need to hire a car for a solo
                    adventure or a spacious SUV for a family trip, our
                    user-friendly website ensures a seamless booking process.
                  </p>
                </div>
              </Col>

              <Col lg="12" className="my-2">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Wide Range of Vehicles
                </h6>
                <div className="popular-services-txt">
                  <p>
                    We take pride in offering a diverse fleet of well-maintained
                    vehicles to cater to your specific needs. From
                    fuel-efficient economy cars to luxurious sedans and rugged
                    4x4s, our selection ensures that you'll find the perfect
                    vehicle to match your preferences and budget.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="text-center d-flex justify-content-center">
              <Col className="col-6 col-sm-5 col-lg-3">
                <button className="header__btn btn ">
                  <Link to="/contact_us">
                    <i class="ri-phone-line"></i> Enquire Now
                  </Link>
                </button>
              </Col>
              <Col className="col-6 col-sm-5 col-lg-3">
                <button className="header__btn btn ">
                  <a href="tel:+64-220211997">
                    <i class="ri-phone-line"></i> Call Now
                  </a>
                </button>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row>
              <Col lg="12" className="">
                <h2 className="section__title">Why Choose Us</h2>
                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  Exceptional Customer Service
                </h6>
                <div className="popular-services-txt">
                  <p>
                    Our commitment to customer satisfaction sets us apart from
                    the competition. Our friendly and knowledgeable team is
                    dedicated to providing personalized assistance, from helping
                    you choose the right car to answering any questions you may
                    have. Your comfort and satisfaction are our top priorities.
                  </p>
                </div>
              </Col>

              <Col lg="12" className="my-2">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Competitive Rates
                </h6>
                <div className="popular-services-txt">
                  <p>
                    We believe that quality car rental services shouldn't break
                    the bank. That's why we offer competitive rental rates
                    without compromising on the quality of our vehicles or
                    service. With Car Rental Christchurch, you can enjoy
                    affordability without sacrificing comfort and reliability.
                  </p>
                </div>
              </Col>

              <Col lg="12" className="my-2">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Convenient Locations
                </h6>
                <div className="popular-services-txt">
                  <p>
                    Convenience is key, and we have strategically located
                    pick-up and drop-off points throughout Christchurch. Whether
                    you're arriving at the airport or need a car in the city
                    center, we have you covered. Our multiple locations make it
                    easy to access your rental vehicle, saving you time and
                    effort.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="text-center d-flex justify-content-center">
              <Col className="col-6 col-sm-5 col-lg-3">
                <button className="header__btn btn ">
                  <Link to="/contact_us">
                    <i class="ri-phone-line"></i> Enquire Now
                  </Link>
                </button>
              </Col>
              <Col className="col-6 col-sm-5 col-lg-3">
                <button className="header__btn btn ">
                  <a href="tel:+64-220211997">
                    <i class="ri-phone-line"></i> Call Now
                  </a>
                </button>
              </Col>
            </Row>
          </Container>
        </section>

        {/* ========== Reviews section =========== */}
        <section>
          <h2 className="section__title text-center p-4">Testimonials</h2>
          <Container className="d-flex flex-wrap justify-content-between">
            {reviews.map((review) => (
              <Col className="d-flex justify-content-center col-12 col-sm-6 col-lg-3 p-2">
                <ReviewCard key={review.id} review={review} />
              </Col>
            ))}
          </Container>
        </section>

        {/* ========== services section ============ */}
        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  See our
                </h6>
                <h2 className="section__title">Popular Services</h2>
              </Col>

              <div className="popular-services-txt">
                <p>
                  At Christchurch Car Rental, we take pride in offering a wide
                  range of services to meet your diverse transportation needs.
                  Whether you're a visitor exploring Christchurch or a local in
                  need of reliable transportation, our popular services are
                  designed to make your journey comfortable and convenient.
                  Here's an overview of our most sought-after services.
                </p>
              </div>

              <ServicesList />
            </Row>
            <Row className="text-center d-flex justify-content-center">
              <Col className="col-6 col-sm-5 col-lg-3">
                <button className="header__btn btn ">
                  <Link to="/contact_us">
                    <i class="ri-phone-line"></i> Enquire Now
                  </Link>
                </button>
              </Col>
              <Col className="col-6 col-sm-5 col-lg-3">
                <button className="header__btn btn ">
                  <a href="tel:+64-220211997">
                    <i class="ri-phone-line"></i> Call Now
                  </a>
                </button>
              </Col>
            </Row>
          </Container>
        </section>
        {/* =========== car offer section ============= */}

        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h2 className="section__title">
                  Frequently Asked Questions (FAQ)
                </h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/contact_us" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <FaqHome />
            </Row>
          </Container>
        </section>
        {/* =========== become a driver section ============ */}
        {/* <BecomeDriverSection /> */}

        {/* =========== testimonial section =========== */}
        {/* <section>
            <Container>
              <Row>
                <Col lg="12" className="mb-4 text-center">
                  <h6 className="section__subtitle">Our clients says</h6>
                  <h2 className="section__title">Testimonials</h2>
                </Col>

                <Testimonial />
              </Row>
            </Container>
          </section> */}

        {/* =============== blog section =========== */}
        {/* <section>
            <Container>
              <Row>
                <Col id="findCar" lg="12" className="mb-5 text-center">
                  <h6 className="section__subtitle">Explore our blogs</h6>
                  <h2 className="section__title">Latest Blogs</h2>
                </Col>

                <BlogList />
              </Row>
            </Container>
          </section> */}
      </motion.div>
    </>
  );
};

export default CarRentalChristChurch;
