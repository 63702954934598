import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';
function WhatsApp() {
  return (
    <div className='w-parent'>
       
            <Link to={'https://wa.me/+64220211997'}>
             <FaWhatsapp color='green' size={'50'}/>
            </Link>
       
        
    </div>
  )
}

export default WhatsApp