import React, { useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder";

import CommonSection from "../components/UI/CommonSection";
// import driveImg from "../assets/all-images/drive.jpg";
import airport_rental1 from "../assets/all-images/airport_rental1.jpg";
// import Helmet from "../components/Helmet/Helmet";
import { motion } from "framer-motion";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import { TbTargetArrow } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import HelmetComponent from "../components/Helmet/HelmetComponent";
import CarItem from "../components/UI/CarItem";
import FaqAirport from "../components/UI/FaqAirportRental";
import {
  allCarsFailure,
  allCarsStart,
  allCarsSuccess,
} from "../components/features/allCarsSlice";
import "../styles/about.css";
import axiosInstance from "../utils/axiosUtil";

const AirportCarRental = () => {
  const { allCars, loadingAllCars, allCarsErr } = useSelector(
    (state) => state.allCars
  );
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const fetchAllCars = async () => {
    dispatch(allCarsStart());
    try {
      const { data } = await axiosInstance.get(`/api/car/get-car?sort=low`);

      dispatch(allCarsSuccess(data.cars));
    } catch (error) {
      dispatch(allCarsFailure(error?.response?.data?.message));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    fetchAllCars();
  }, []);

  return (
    <>
      <HelmetComponent
        title={"Christchurch Airport Car Rental | Hire Now"}
        metaDesc={
          "Hire car at Christchurch Airport & get Quick, Easy, and Affordable Rental Car. | Hire Now | Christchurch Car Rental"
        }
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <CommonSection title="Airport Car Rental Christchurch" />

        <section className="" style={{}}>
          <Container>
            <Row className="justify-content-between">
              <Col lg="6" md="6">
                <div>
                  {/* <h4 className="section__subtitle">About Us</h4> */}
                  <h2 className="section__title">
                    Christchurch Airport Car Rental
                    <br />
                  </h2>
                  <p className="section__description">
                  Are you searching for a <strong>Christchurch car rental airport</strong> service that is both affordable and convenient? Look no further! At Car Rental Christchurch, we provide a seamless rental experience with a wide selection of vehicles to suit your travel needs. Whether you’re in town for business or an adventure, we have the perfect car for you.

                  </p>
                </div>
              </Col>

              <Col lg="4" md="6">
                <div className=" aiport_rental_image">
                  <img src={airport_rental1} alt="" className="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ backgroundColor: "#fff" }}>
          <Container>
            <Row className="justify-content-between align-items-center">
              {/* {width <= 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={blog3} alt="" className="w-100" />
                  </div>
                </Col>
              )} */}

             
              
            </Row>

            <Row className="mt-lg-5 mt-md-3 justify-content-between align-items-center">
              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  Why Choose Us for Car Rental in Christchurch?

                </h2>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                  }}
                >
                  <TbTargetArrow /> Affordable Prices
                </h6>
                <p style={{ color: "#868686" }}>
                We offer <strong>cheap car hire Christchurch airport</strong> options without compromising on quality.

                </p>
              </Col>
            
              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow />Convenient Airport Pickup
                </h6>

                <p style={{ color: "#868686" }}>
                Our <strong>car rental Christchurch airport</strong> service ensures you can pick up your vehicle as soon as you land.
                </p>
              </Col> 
              <Row className="mt-lg-1 mt-md-3 justify-content-between align-items-center">
              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow />Wide Range of Vehicles
                </h6>

                <p style={{ color: "#868686" }}>
                From compact city cars to spacious SUVs, we cater to all travelers.

                </p>
              </Col>

              </Row>
              <Row className="mt-lg-1 mt-md-2 justify-content-between align-items-center">
              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow />Easy Booking Process
                </h6>

                <p style={{ color: "#868686" }}>
                Secure your rental online in just a few clicks.
                </p>
              </Col>
              </Row>
              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow />No Hidden Fees 
                </h6>

                <p style={{ color: "#868686" }}>
                Transparent pricing with no surprises.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section style={{ backgroundColor: "#fff" }}>
          <Container>
            <Row className="justify-content-between align-items-center">
            <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                Cheap Car Rental Christchurch Airport – Explore with Ease


                </h2>

               
                <p style={{ color: "#868686" }}>
                Whether you’re planning a road trip through the South Island or need a car for a short stay, our <strong>cheap car rental Christchurch airport</strong> service makes it simple and affordable. With unlimited kilometers and flexible rental terms, you can enjoy your journey without restrictions.


                </p>
              </Col>
              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
              Book Your Car Hire Christchurch Airport Today!



                </h2>

               
                <p style={{ color: "#868686" }}>
                Avoid the hassle of taxis or public transport—choose our <strong>car hire Christchurch airport</strong> service and start your trip stress-free. Reserve your vehicle now and experience the best car rental service in Christchurch!


                </p>
              </Col>

             
              
            </Row>
            </Container>
            </section>
        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Come with
                </h6>
                <h2 className="section__title">Hot Offers</h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/cars" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                <Row className="row-car-list">
                  {allCars?.slice(0, 6)?.map((item) => (
                    <CarItem item={item} key={item._id} />
                  ))}
                </Row>
              </ReactPlaceholder>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h2 className="section__title">
                  Frequently Asked Questions (FAQ)
                </h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/faq" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <FaqAirport />
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row className="">
              <Col lg="12" className="mb-5 ">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  Your Travel Adventure Awaits
                </h2>

                <p style={{ color: "#868686" }}>
                  Experience the ease and convenience of Christchurch
                  Airport Car Rental. With our prime airport location, diverse
                  rental options, competitive pricing, and unwavering commitment
                  to customer satisfaction, we ensure that your journey in
                  Christchurch starts on the right foot. Begin your
                  adventure by booking your rental car today at{" "}
                  <a
                    href="https://www.carrentalchristchurch.co.nz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.carrentalchristchurch.co.nz{" "}
                    <LiaExternalLinkAltSolid
                      style={{ marginLeft: "0.2rem", marginBottom: "0.2rem" }}
                    />
                  </a>
                  . Discover Christchurch and beyond at your own pace.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>
    </>
  );
};

export default AirportCarRental;
