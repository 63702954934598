import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "../../styles/payment-method.css";
import axiosInstance from "../../utils/axiosUtil";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PaymentStripe from "./PaymentStripe";

const PaymentMethod = () => {
  const navigate = useNavigate();
  const { carDetails, insuranceSelected, total, userDetails } = useSelector(
    (state) => state.bookingCar
  );
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [bookedDays, setBookedDays] = useState(0);

  const daysDifference = () => {
    const dateTimeDiff =
      new Date(
        `${format(new Date(userDetails?.journeyEndDate), "MMMM dd, yyyy")}, ${
          userDetails?.journeyEndTime
        }`
      ) -
      new Date(
        `${format(new Date(userDetails?.journeyDate), "MMMM dd, yyyy")}, ${
          userDetails?.journeyTime
        }`
      );

    setBookedDays(Math.ceil(dateTimeDiff / (1000 * 60 * 60 * 24)));

    let total = 0;
    total = (
      carDetails?.price *
      Math.round(Math.abs(dateTimeDiff / (1000 * 60 * 60 * 24)))
    )?.toFixed(2);

    setTotalAmount(total);
  };

  useEffect(() => {
    daysDifference();
  }, []);

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const createOrder = async () => {
    try {
      const { data } = await axiosInstance.post("/api/booking/create-booking", {
        startDate: userDetails?.journeyDate,
        endDate: userDetails?.journeyEndDate,
        startTime: userDetails?.journeyTime,
        endTime: userDetails?.journeyEndTime,
        carId: carDetails?._id,
        // insurance: insuranceSelected,
        discount: carDetails?.discount,
        insurance: parseInt(localStorage.getItem("insurance")),
        insuranceType: localStorage.getItem("insuranceType"),
        // insuranceDetails: userDetails?.insuranceDetails
      });

      return data.order.id;
    } catch (error) {
      toast.error("Something went wrong. Please try again!", toastOptions);
    }
  };

  const onApprove = async (data) => {
    try {
      setLoading(true);
      const { data: onApproveData } = await axiosInstance.post(
        `/api/booking/capture-payment/${data.orderID}`,
        {
          startDate: userDetails?.journeyDate,
          endDate: userDetails?.journeyEndDate,
          startTime: userDetails?.journeyTime,
          endTime: userDetails?.journeyEndTime,
          carId: carDetails?._id,
          insurance: insuranceSelected,
          person: userDetails?.person,
          luggage: userDetails?.luggage,
          fromAddress: userDetails?.fromAddress,
          toAddress: userDetails?.toAddress,
          discount: carDetails?.discount,
          name: localStorage.getItem("name"),
          email: localStorage.getItem("email"),
          phone: localStorage.getItem("phone"),
          insuranceType: localStorage.getItem("insuranceType")
        // insuranceDetails: userDetails?.insuranceDetails
          
        }
      );

      if (onApproveData.paymentCaptured.status === "COMPLETED") {
        window.scroll(0, 0);
        setLoading(false);
        navigate("/", { replace: true });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again!", toastOptions);
    }
  };

  return (
    <>
      <div className="payment-breakdown-root">
        <div className="payment-breakdown-cont">
          <p className="payment-breakdown-titlep">
            {carDetails?.carName + " Booked for " + bookedDays + " day(s)"}
          </p>

          <p className="payment-breakdown-amt">
            <strong>${carDetails?.price * bookedDays}</strong>
          </p>
        </div>
        {insuranceSelected ? (
          <div className="payment-breakdown-cont">
            <p className="payment-breakdown-titlep">
              Optional Add ons - Insuarance <br />
              Fees A Day:
            </p>
            <p className="payment-breakdown-amt">
              <strong>$0</strong>
            </p>
          </div>
        ) : (
          <div className="payment-breakdown-cont">
            <p className="payment-breakdown-titlep">
              Optional Add ons - Insuarance ${localStorage.getItem("insurance")}{" "}
              Per Day
            </p>
            <p className="payment-breakdown-amt">
              <strong>
                $
                {parseInt(localStorage.getItem("insurance")) *
                  parseInt(localStorage.getItem("journeyDays"))}
              </strong>
            </p>
          </div>
        )}
        {insuranceSelected ? (
          <div className="payment-breakdown-cont">
            <div>
              <p className="mb-0 payment-breakdown-titlep">
                Additional Per day 0 dollars
              </p>
              <p className="payment-breakdown-titlep">
                {"For " + bookedDays + " day(s) insurance "}
              </p>
            </div>
            <div className="align-items-end justify-content-end d-flex flex-column">
              <p className="mb-0 payment-breakdown-amt">
                {"$" + 0 + "+" + "$" + (bookedDays - 1) * 0}
              </p>
              <p className="payment-breakdown-amt">
                <strong>${0 + (bookedDays - 1) * 0}</strong>
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* {insuranceSelected ? (
          <div className="payment-breakdown-cont">
            <p className="payment-breakdown-titlep">Plus GST 15%</p>
            <p className="payment-breakdown-amt">
              <strong>
                $
                {carDetails?.price * bookedDays +
                  0 +
                  (bookedDays - 1) * 0 +
                  (carDetails?.price * bookedDays + 0 + (bookedDays - 1) * 0) *
                    (15 / 100)}
              </strong>
            </p>
          </div>
        ) : (
          <div className="payment-breakdown-cont">
            <p className="payment-breakdown-titlep">Plus GST 15%</p>
            <p className="payment-breakdown-amt">
              <strong>
                ${0 + carDetails?.price * bookedDays * (15 / 100)}
              </strong>
            </p>
          </div>
        )} */}
        <div className="payment-breakdown-cont" style={{ color: "red" }}>
          <p>
            <h5>
              <strong>Discount</strong>
            </h5>
          </p>
          <p className="payment-breakdown-amt">
            <h5>
              <strong>
                {bookedDays > 7 ? `${carDetails?.discount || 0}%` : `0%`}
              </strong>
            </h5>
          </p>
        </div>

        {/* <div className="payment-breakdown-cont" style={{ color: "green" }}>
          <p>
            <p>
              <strong>
                Additional $100 (Refundable when you Returning a Car)
              </strong>
            </p>
          </p>
          <p className="payment-breakdown-amt">
            <h5>
              <strong>$100</strong>
            </h5>
          </p>
        </div> */}

        <div className="payment-breakdown-cont mt-4">
          <h3>
            <strong>Total</strong>
          </h3>
          <h3>
            <strong>${total?.toFixed(2)}</strong>
          </h3>
        </div>
        <p className="mt-4 align-items-center d-flex justify-content-center">
          Pay now to confirm
        </p>
      </div>

      {/* <PayPalScriptProvider
        options={{
          clientId:
            "AX535GFAQkFV1IdrHNQxdw0pAjyd7MyMxs0mz7sAtIVroCfy8rOYvrAOEKw3OPr4PLIlzGd1ZhJ2rmdQ",
          components: "buttons,hosted-fields",
          currency: "NZD",
        }}
      >
        <PayPalButtons
          disabled={loading}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </PayPalScriptProvider> */}
      <PaymentStripe
        amt={total}
        setLoading={setLoading}
        insuranceSelected={insuranceSelected}
        carDetails={carDetails}
        createOrder={createOrder}
        disabled={loading}
        userDetails={userDetails}
      />

      <ToastContainer />
    </>
  );
};

export default PaymentMethod;
